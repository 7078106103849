let responseMixin = {
  props: {
    query: {
      type: Object,
      default: () => {
        return {

        }
      }
    }
  },
  watch: {
    query: {
      handler(value) {
        this.searchEvent()
      },
      deep: true
    }
  },
  data() {
    return {
      loading: false,
      finished: false,
      refreshing: false,
      page: 1,
      pageSize: 50,
      tableData: [],
    }
  },
  beforeDestroy() {
    this.$bus.off('searchEvent', this.searchEvent)
  },
  mounted() {
    this.$bus.on('searchEvent', this.searchEvent)
  },
  methods: {
    searchEvent:function() {
      this.page = 1;
      this.onLoad();
    },
    onRefresh: function() {
      this.page = 1
      this.finished = false
      this.refreshing = true
      this.getDataList().then(res => {
        this.refreshing = false
        this.finished = true
        this.tableData = res.list
      })
    },
    onLoad: function() {
      this.finished = false
      this.loading = true
      this.getDataList().then(res => {
        this.finished = true
        this.loading = false
        this.tableData = res.list
      })
    },
    getDataList: function(res) {
      return new Promise(resolve => {
        let startTime = ''
        if (this.$valueIsExist(this.query, 'timeStamp')) {
          startTime = this.query['timeStamp']
        }

        let query = ''
        if (this.$valueIsExist(this.query, 'query')) {
          query = this.query['query']
        }
        this.$api.deviceRepair
          .reportList({
            query: query,
            startTime: startTime,
            queryType: this.queryType,
            page: this.page,
            pageSize: this.pageSize
          }).then(res => {
          let list = []
          if (this.$valueIsExist(res, 'data')) {
            list = res['data']
          }
          resolve({ list: list })
        }).catch(e => {
          resolve({ list: [] })
        })
      })

    }
  }
}

export default responseMixin