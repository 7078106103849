<template>
  <div class="responseCell" @click.stop="pushDetail">
    <div class="responseCellContent">
      <div class="header">
        <div class="status">{{ severity }}</div>
        <div class="responseTitle">
          {{ deviceName }}维修申请
        </div>
        <div class="responseStatus" :style="{color:color}">{{ status }}</div>
        <div style="clear: both"></div>
      </div>
      <div class="content">
        <img class="deviceImg" :src="image">
        <div class="deviceContent">
          <div class="totalCell">
            {{ deviceName }}
          </div>

          <div class="contentCell">
            <div class="title">工单编号</div>
            <div class="cellMsg">{{ serialNumber }}</div>
            <div style="clear: both"></div>
          </div>

          <div class="contentCell">
            <div class="title">{{$VUEAPPDEVICECODE()}}</div>
            <div class="cellMsg">{{ deviceCode }}</div>
            <div style="clear: both"></div>
          </div>

          <div class="contentCell">
            <div class="title">报障时间</div>
            <div class="cellMsg">{{ reportTime }}</div>
            <div style="clear: both"></div>
          </div>

        </div>
        <div style="clear: both"></div>
      </div>

      <div class="footer">
        <slot name="footer"></slot>
      </div>

    </div>
  </div>
</template>

<script>

import shareDeviceOrderStatus from '@components/shareDeviceOrderStatus/shareDeviceOrderStatus'

export default {
  name: 'responseCell',
  mixins: [shareDeviceOrderStatus],
  props: {
    dataInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    image() {
      if (this.$valueIsExist(this.dataInfo, 'photo') && this.dataInfo['photo'] != '') {
        let photo = this.dataInfo['photo']
        return `${window.ipConfig.VUE_APP_BASEURL}/api/image/get?id=${this.$getPhotosId(photo)}`
      }
      return this.$replacePhoto('')
    },
    statusData() {
      let status = 0
      if (this.$valueIsExist(this.dataInfo, 'status')) {
        status = this.dataInfo['status']
      }
      return this.getStatusData(status)
    },
    color() {
      let status = 0
      if (this.$valueIsExist(this.dataInfo, 'status')) {
        status = this.dataInfo['status']
      }
      return this.statusData.color
    },
    status() {
      let status = 0
      if (this.$valueIsExist(this.dataInfo, 'status')) {
        status = this.dataInfo['status']
      }
      return this.statusData.name
    },
    severity() {
      let severity = 0
      if (this.$valueIsExist(this.dataInfo, 'severity')) {
        severity = this.dataInfo['severity']
      }
      return this.getSeverityData(severity)
    },
    deviceCode() {
      if (this.$valueIsExist(this.dataInfo, 'deviceCode') == false) {
        return ''
      }
      return this.dataInfo['deviceCode']
    },
    categoryName() {
      if (this.$valueIsExist(this.dataInfo, 'categoryName') == false) {
        return ''
      }
      return this.dataInfo['categoryName']
    },
    address() {
      if (this.$valueIsExist(this.dataInfo, 'address') == false) {
        return ''
      }
      return this.dataInfo['address']
    },
    serialNumber() {
      if (this.$valueIsExist(this.dataInfo, 'serialNumber') == false) {
        return ''
      }
      return this.dataInfo['serialNumber']
    },
    reportUser() {
      if (this.$valueIsExist(this.dataInfo, 'reportUser') == false) {
        return ''
      }
      return this.dataInfo['reportUser']
    },
    deviceName() {
      if (this.$valueIsExist(this.dataInfo, 'deviceName') == false) {
        return ''
      }
      return this.dataInfo['deviceName']
    },
    reportTime() {
      if (this.$valueIsExist(this.dataInfo, 'reportTime') == false) {
        return ''
      }
      return this.formatDate(this.dataInfo['reportTime'])
    },
    repairTime() {
      if (this.$valueIsExist(this.dataInfo, 'repairTime') == false) {
        return ''
      }
      return this.formatDate(this.dataInfo['repairTime'])
    }
  },
  data() {
    return {
    }
  },
  methods: {
    pushDetail: function() {
      let id = ''
      if (this.$valueIsExist(this.dataInfo, 'id')) {
        id = this.dataInfo['id']
      }
      this.$push('deviceView1/responseDetail', { id: id })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@styles/variables.scss";

.responseCell {
  width: 100%;

  .responseCellContent {
    border-radius: 10px;
    width: calc(100% - 20px);
    margin-left: 10px;
    margin-top: 10px;
    overflow: hidden;
    background-image: linear-gradient(180deg, #D1DEFF 0%, #FFFFFF 100%);;

    .header {
      width: 100%;
      padding: 5px 10px 0px 10px;
      margin-bottom: 11px;


      .status {
        width: 44px;
        background-color: red;
        height: 24px;
        text-align: center;
        line-height: 24px;
        color: white;
        float: left;
        font-size: 14px;
        border-radius: 5px;
      }

      .responseTitle {
        width: calc(100% - 145px);
        margin-left: 10px;
        float: left;
        font-size: 14px;
        font-weight: 400;
        height: 24px;
        line-height: 24px;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .responseStatus {
        width: 70px;
        height: 24px;
        margin-left: 5px;
        float: right;
        font-size: 14px;
        text-align: right;
        line-height: 24px;
      }
    }


    .content {
      width: 100%;
      padding: 0px 10px 0px 10px;


      .deviceImg {
        width: 60px;
        height: 60px;
        border-radius: 8px;
        float: left;
      }

      .deviceContent {
        width: calc(100% - 90px);
        float: right;
        margin-left: 5px;

        .totalCell {
          width: 100%;
          height: 14px;
          line-height: 14px;
          font-size: 14px;
          margin-bottom: 6px;
        }

        .contentCell {
          margin-bottom: 6px;

          .title {
            width: 80px;
            height: 12px;
            line-height: 12px;
            float: left;
            font-size: 12px;
            color: $color3;
          }

          .cellMsg {
            float: right;
            width: calc(100% - 90px);
            height: 12px;
            line-height: 12px;
            font-size: 12px;
            color: $color3;
          }

        }

      }


    }

    .footer {
      width: 100%;
      padding: 5px 10px 10px 10px;


      .footerMsg {
        width: calc(100% - 135px);
        line-height: 28px;
        float: left;
        word-wrap: break-word;
        word-break: break-all;
        white-space: pre-wrap;
      }

      .actionButton {
        float: right;
        height: 28px;
        width: 120px;
        border-radius: 14px;
        line-height: 28px;
        overflow: hidden;
        color: $color_primary;
        border: 1px solid $color_primary;
        text-align: center;
      }


    }

  }

}

</style>